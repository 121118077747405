import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/klinik.css";

import { FaReceipt } from "react-icons/fa";
import { GrDocumentTest, GrDocumentCloud } from "react-icons/gr";
import ReactTooltip from "react-tooltip";

import ModalChooseEvent from "../Kesehatan/ModalChooseEvent";
import ModalPDFViewer from "../../components/ModalPDFViewer";
import Search from "../../components/Search";
import Filter from "../../components/Filter";
import FilterCalender from "../../components/FilterCalender";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClinic,
  getReservasiData,
} from "../../store/actions";
import { getColor } from "../../components/getColor";
import Medqcare from "../../asset/svg/medqcare";
import IcPolygonUp from "../../asset/svg/polygonUp";
import IcPolygonDown from "../../asset/svg/polygonDown";
import formatDate from "../../helpers/formatDate";
import formatSimpleDate from "../../helpers/formatSimpleDate";
import getLastOneMonth from "../../helpers/getLastOneMonth";

function ListKlinik() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataKlinik } = useSelector((state) => state.clinicState);
  const { groupEvents, dataReservasi } = useSelector((state) => state.eventState);

  const [data, setData] = useState([]); //all data
  const [dataUmum, setDataUmum] = useState([]);
  const [dataUmumRender, setDataUmumRender] = useState([]);
  const type = ["Event", "Umum"];
  const [selectedType, setSelectedType] = useState("Event");
  const [showEventModal, setShowEventModal] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const itemPerPage = 10;
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [arrayPage, setArrrayPage] = useState([]);

  const [faskesModal, showFaskesModal] = useState(false);
  const [faskes, setFaskes] = useState([]);
  const [selectedFaskes, setSelectedFaskes] = useState("");

  const [statusModal, showStatusModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [divisiModal, showDivisiModal] = useState(false);
  const [divisi, setDivisi] = useState([]);
  const [selectedDivisi, setSelectedDivisi] = useState("");

  const [tanggalModal, showTanggalModal] = useState(false);
  const [selectedTanggal, setSelectedTanggal] = useState("");
  const [selectedRange, setSelectedRange] = useState({});

  const [modalPdf, setModalPdf] = useState(false)
  const [docType, setDocType] = useState("")
  const [urlPdf, setUrlPdf] = useState("");

  useEffect(() => {
    dispatch(getAllClinic());
    const lastMonth = getLastOneMonth()
    setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
    setSelectedRange(getLastOneMonth());
    setDivisi(["Semua Divisi"]);
    setStatus(["Semua Status", "Sedang Berlangsung", "Selesai"]);
    setFaskes(["Semua Faskes"]);

    setSelectedDivisi("Semua Divisi");
    setSelectedFaskes("Semua Faskes");
    setSelectedStatus("Semua Status");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataKlinik.length > 0) {
      setData(dataKlinik);
    }
    // eslint-disable-next-line
  }, [dataKlinik]);

  useEffect(() => {
    if (selectedRange.startDate && selectedType === "Umum") {
      let StartDate = selectedRange.startDate;
      let EndDate = selectedRange.endDate;
      dispatch(getReservasiData(StartDate, EndDate));
    }
    // eslint-disable-next-line
  }, [selectedRange, selectedType]);

  useEffect(() => {
    const allDivisi = ["Semua Divisi"];
    const allFaskes = ["Semua Faskes"];
    dataReservasi.forEach((el) => {
      if (!allDivisi.includes(el.employee.department)) {
        allDivisi.push(el.employee.department);
      }
      if (!allFaskes.includes(el.healthFacility.facilityName)) {
        allFaskes.push(el.healthFacility.facilityName);
      }
    });
    setDivisi(allDivisi);
    setFaskes(allFaskes);
    setDataUmum(dataReservasi);
  }, [dataReservasi]);

  useEffect(() => {
    setTotalPage(Math.ceil(dataUmum.length / itemPerPage));
    setDataUmumRender(
      dataUmum.slice(
        (activePage - 1) * itemPerPage,
        (activePage - 1) * itemPerPage + itemPerPage
      )
    );
    // eslint-disable-next-line
  }, [dataUmum, activePage]);

  useEffect(() => {
    const array = [];
    if (activePage > 1) array.push(activePage - 1);
    array.push(activePage);
    if (totalPage > activePage) array.push(activePage + 1);
    if (array.length < 3 && totalPage > activePage + 1)
      array.push(activePage + 2);
    if (array.length < 3 && activePage > 2) array.unshift(activePage - 2);
    setArrrayPage(array);
    // eslint-disable-next-line
  }, [totalPage, activePage]);

  useEffect(() => {
    if (selectedType === "Event") {
      if (searchName === "") {
        setData(dataKlinik);
      } else if (dataKlinik.length) {
        var regexp = new RegExp(searchName, "gi");
        const newData = dataKlinik.filter((el) => {
          return el.name.match(regexp) !== null;
        });
        setData(newData);
      }
    } else if (selectedType === "Umum") {
      let newData = [];
      dataReservasi.forEach((el) => {
        let flag = true;
        let regexp = new RegExp(searchName, "gi");
        if (searchName !== "" && el.employee.name.match(regexp) === null) {
          flag = false;
        } else if (
          selectedDivisi !== "Semua Divisi" &&
          selectedDivisi !== el.employee.department
        ) {
          flag = false;
        } else if (
          selectedStatus !== "Semua Status" &&
          selectedStatus !== el.status
        ) {
          flag = false;
        } else if (
          selectedFaskes !== "Semua Faskes" &&
          selectedFaskes !== el.healthFacility.facilityName
        ) {
          flag = false;
        }
        if (flag) {
          newData.push(el);
        }
      });
      if (sortType === "ASC") {
        if (sortBy === "name" || sortBy === "department") {
          newData.sort((a, b) =>
            a.employee[sortBy].localeCompare(b.employee[sortBy])
          );
        }
        if (sortBy === "bookingSchedule") {
          newData.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
        }
        if (sortBy === "faskes") {
          newData.sort((a, b) =>
            a.healthFacility.facilityName.localeCompare(
              b.healthFacility.facilityName
            )
          );
        }
        if (sortBy === "price") {
          newData.sort((a, b) => a[sortBy] - b[sortBy]);
        }
        if (sortBy === "orderType") {
          newData.sort((a, b) => {
            let n = a.orderType.localeCompare(b.orderType);
            if (a.orderType === "services" && b.orderType === "services") {
              return a.services.name.localeCompare(b.services.name);
            } else {
              return n;
            }
          });
        }
      } else if (sortType === "DESC") {
        if (sortBy === "name" || sortBy === "department") {
          newData.sort((a, b) =>
            b.employee[sortBy].localeCompare(a.employee[sortBy])
          );
        }
        if (sortBy === "bookingSchedule") {
          newData.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
        }
        if (sortBy === "faskes") {
          newData.sort((a, b) =>
            b.healthFacility.facilityName.localeCompare(
              a.healthFacility.facilityName
            )
          );
        }
        if (sortBy === "price") {
          newData.sort((a, b) => b[sortBy] - a[sortBy]);
        }
        if (sortBy === "orderType") {
          newData.sort((a, b) => {
            let n = b.orderType.localeCompare(a.orderType);
            if (b.orderType === "services" && a.orderType === "services") {
              return b.services.name.localeCompare(a.services.name);
            } else {
              return n;
            }
          });
        }
      }
      setDataUmum(newData);
      setActivePage(1);
    }
    // eslint-disable-next-line
  }, [
    searchName,
    selectedDivisi,
    selectedStatus,
    selectedFaskes,
    sortType,
    sortBy,
  ]);

  //filter
  const selectFilter = (filtername, filter) => {
    switch (filtername) {
      case "divisi":
        showDivisiModal(false);
        setSelectedDivisi(filter);
        break;

      case "status":
        showStatusModal(false);
        setSelectedStatus(filter);
        break;

      case "faskes":
        showFaskesModal(false);
        setSelectedFaskes(filter);
        break;

      case "tanggal":
        showTanggalModal(false);
        setSelectedTanggal(filter);
        break;

      default:
        break;
    }
  };

  const sortHandle = async (field) => {
    if (sortBy !== field) {
      setSortBy(field);
    }
    if (sortBy === field) {
      sortType === "ASC" ? await setSortType("DESC") : await setSortType("ASC");
    } else {
      await setSortType("ASC");
    }
  };

  const closeModal = () => {
    showStatusModal(false);
    showFaskesModal(false);
    showTanggalModal(false);
    showDivisiModal(false);
  }

  return (
    <div className="bgcontent">
      <div className="tagihan">
        <div className="header">{"Event & Tagihan"}</div>
        <div className="row">
          <Search
            searchName={searchName}
            setSearchName={setSearchName}
            placeholder={"Pencarian"}
          />
          <div className="event ml">
            {type.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className="eventname"
                  style={{
                    color: selectedType === el ? "#109CF1" : "#8891A0",
                    borderColor: selectedType === el ? "#109CF1" : "#8891A0",
                  }}
                  onClick={() => setSelectedType(el)}
                >
                  {el}
                </div>
              );
            })}
          </div>
          {selectedType === "Event" && (
            <div className="button" onClick={() => setShowEventModal(true)}>
              Tambah Event
            </div>
          )}
        </div>
        {showEventModal && (
          <ModalChooseEvent
            setModal={setShowEventModal}
            groupEvents={groupEvents}
          />
        )}
        {selectedType === "Event" ? (
          <>
            {data.length > 0 ? (
              <>
                {data?.map((el) => {
                  return (
                    <div
                      className="carditem mt20"
                      key={el._id}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/event/detail/${el._id}`, {
                          replace: true,
                          state: { dataKlinik: el },
                        });
                      }}
                    >
                      <div>
                        <div className="row">
                          <img
                            src={
                              el.klinikImage
                                ? el.klinikImage
                                : require("../../asset/png/klinik.png")
                            }
                            height={80}
                            width={85}
                            alt="klinik"
                          />
                          <div className="name">
                            <div className="title">{el.name}</div>
                            <div className="report">
                              <div className="detailreport mr">
                                Pemeriksaan Terakhir
                              </div>
                              {el.lastEvent && (
                                <div
                                  className="status"
                                  style={{
                                    borderColor: getColor(el.lastEvent.status),
                                    color: getColor(el.lastEvent.status),
                                  }}
                                >
                                  {el.lastEvent.status}
                                </div>
                              )}
                            </div>
                            {el.lastEvent ? (
                              <div className="detailreport">
                                {formatDate(el.lastEvent.date)}
                              </div>
                            ) : (
                              <div
                                className="detailreport"
                                style={{ fontWeight: "bold" }}
                              >
                                {"Belum ada event"}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mt20">
                          <div style={{ width: "130px" }} className="grayText">
                            <div className="font13">Total Tagihan</div>
                            <div className="mt font16 darkBlueText">
                              {el.totalTagihan
                                ? "Rp " +
                                el.totalTagihan.toLocaleString("id-ID")
                                : "-"}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "1px",
                              backgroundColor: "#D6D6D6",
                              marginRight: "15px",
                            }}
                          />
                          {/* <div style={{ width: "130px" }} className="goldText">
                        <div className="font13">Total Pagu</div>
                        <div className="mt font16">{el.totalPagu || "-"}</div>
                      </div>
                      <div
                        style={{
                          width: "1px",
                          backgroundColor: "#D6D6D6",
                          marginRight: "15px",
                        }}
                      /> */}
                          <div style={{ width: "130px" }} className="redText">
                            <div className="font13">Jatuh Tempo</div>
                            <div className="mt font16">
                              {el.jatuhTempo || "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detail">Lihat Detail Transaksi</div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div style={{ padding: "150px" }}>
                  <Medqcare />
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      color: "#c6cbcf",
                      marginTop: "20px",
                    }}
                  >
                    Tidak ada data tersedia
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="filter mt20">
              <div className="row">
                <Filter
                  filterName={"divisi"}
                  modal={divisiModal}
                  closeModal={closeModal}
                  showModal={() => {
                    showStatusModal(false);
                    showFaskesModal(false);
                    showTanggalModal(false);
                    divisiModal
                      ? showDivisiModal(false)
                      : showDivisiModal(true);
                  }}
                  item={divisi}
                  selectedItem={selectedDivisi}
                  selectFilter={selectFilter}
                  first={true}
                />
                <Filter
                  filterName={"status"}
                  modal={statusModal}
                  closeModal={closeModal}
                  showModal={() => {
                    showDivisiModal(false);
                    showFaskesModal(false);
                    showTanggalModal(false);
                    statusModal
                      ? showStatusModal(false)
                      : showStatusModal(true);
                  }}
                  item={status}
                  selectedItem={selectedStatus}
                  selectFilter={selectFilter}
                />
                <FilterCalender
                  modal={tanggalModal}
                  closeModal={closeModal}
                  selectedItem={selectedTanggal}
                  setSelectedTanggal={setSelectedTanggal}
                  setSelectedRange={setSelectedRange}
                  showModal={() => {
                    showDivisiModal(false);
                    showFaskesModal(false);
                    showStatusModal(false);
                    tanggalModal
                      ? showTanggalModal(false)
                      : showTanggalModal(true);
                  }}
                />
                <Filter
                  filterName={"faskes"}
                  modal={faskesModal}
                  closeModal={closeModal}
                  showModal={() => {
                    showDivisiModal(false);
                    showTanggalModal(false);
                    showStatusModal(false);
                    faskesModal
                      ? showFaskesModal(false)
                      : showFaskesModal(true);
                  }}
                  item={faskes}
                  selectedItem={selectedFaskes}
                  selectFilter={selectFilter}
                />
                <div
                  className="reset"
                  onClick={() => {
                    setSearchName("");
                    setSelectedDivisi("Semua Divisi");
                    setSelectedStatus("Semua Status");
                    setSelectedFaskes("Semua Faskes");
                    const lastMonth = getLastOneMonth()
                    setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
                    setSelectedRange(getLastOneMonth());
                    showDivisiModal(false);
                    showTanggalModal(false);
                    showStatusModal(false);
                    showFaskesModal(false);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
            <table className="mt20">
              <thead className="tablehead">
                <tr>
                  <td className="columnnama">
                    <div
                      className="columntitle"
                      onClick={() => sortHandle("name")}
                    >
                      <div style={{ fontWeight: "bold" }}>NAMA</div>
                      <div className="sort">
                        <IcPolygonUp
                          active={sortBy === "name" && sortType === "ASC"}
                        />
                        <IcPolygonDown
                          active={sortBy === "name" && sortType === "DESC"}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="column">
                    <div
                      className="columntitle"
                      onClick={() => sortHandle("department")}
                    >
                      <div style={{ fontWeight: "bold" }}>DIVISI</div>
                      <div className="sort">
                        <IcPolygonUp
                          active={sortBy === "department" && sortType === "ASC"}
                        />
                        <IcPolygonDown
                          active={
                            sortBy === "department" && sortType === "DESC"
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td className="column">
                    <div
                      className="columntitle"
                      onClick={() => sortHandle("bookingSchedule")}
                    >
                      <div style={{ fontWeight: "bold" }}>TGL PERIKSA</div>
                      <div className="sort">
                        <IcPolygonUp
                          active={
                            sortBy === "bookingSchedule" && sortType === "ASC"
                          }
                        />
                        <IcPolygonDown
                          active={
                            sortBy === "bookingSchedule" && sortType === "DESC"
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td className="column">
                    <div
                      className="columntitle"
                      onClick={() => sortHandle("orderType")}
                    >
                      <div style={{ fontWeight: "bold" }}>PEMERIKSAAN</div>
                      <div className="sort">
                        <IcPolygonUp
                          active={sortBy === "orderType" && sortType === "ASC"}
                        />
                        <IcPolygonDown
                          active={sortBy === "orderType" && sortType === "DESC"}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="column">
                    <div
                      className="columntitle"
                      onClick={() => sortHandle("faskes")}
                    >
                      <div style={{ fontWeight: "bold" }}>FASKES</div>
                      <div className="sort">
                        <IcPolygonUp
                          active={sortBy === "faskes" && sortType === "ASC"}
                        />
                        <IcPolygonDown
                          active={sortBy === "faskes" && sortType === "DESC"}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="column">
                    <div
                      className="columntitle"
                      onClick={() => sortHandle("price")}
                    >
                      <div style={{ fontWeight: "bold" }}>BIAYA</div>
                      <div className="sort">
                        <IcPolygonUp
                          active={sortBy === "price" && sortType === "ASC"}
                        />
                        <IcPolygonDown
                          active={sortBy === "price" && sortType === "DESC"}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="columnaksi" style={{ fontWeight: "bold" }}>
                    HASIL
                  </td>
                </tr>
              </thead>
              <tbody>
                {dataUmumRender.length > 0 &&
                  dataUmumRender.map((el) => {
                    return (
                      <tr className="tablebody" key={el._id}>
                        <th className="columnbodynama">{el.employee.name}</th>
                        <th className="columnbody">{el.employee.department}</th>
                        <th className="columnbody">
                          {formatSimpleDate(el.bookingSchedule)}
                        </th>
                        <th className="columnbody">
                          {el.services?.name || "Dokter Umum"}
                        </th>
                        <th className="columnbody">
                          {el.healthFacility.facilityName}
                        </th>
                        <th className="columnbody">
                          {el.price
                            ? `Rp ${el.price.toLocaleString("id-ID")}`
                            : "-"}
                        </th>
                        <th className="columnbodyaksi">
                          {el.status === "Selesai" ? (
                            <div className="row">
                              <div 
                                data-tip data-for="lab"
                                className={el.resumeMedis?.file?.fileUrl ? "pointer" : ""} 
                                style={{
                                  marginRight: "10px",
                                  display: el.resumeMedis?.file?.fileUrl ? "" : "none"
                                }}
                                onClick={() => {
                                  setUrlPdf(el.resumeMedis?.file?.fileUrl)
                                  setDocType("Hasil Pemeriksaan Lab")
                                  setModalPdf(true)
                              }}><GrDocumentCloud /></div>
                              <ReactTooltip id="lab" type="warning">
                                <span>Hasil Lab</span>
                              </ReactTooltip>
                              <div 
                                data-tip data-for="tagihan"
                                className={el.struk && el.struk.fileUrl ? "pointer" : ""} 
                                style={{
                                  marginRight: "10px",
                                  display: el.struk && el.struk.fileUrl ? "" : "none"
                                }}
                                onClick={() => {
                                  setUrlPdf(el.struk.fileUrl)
                                  setDocType("Struk Tagihan")
                                  setModalPdf(true)
                              }}>
                                <FaReceipt />
                              </div>
                              <ReactTooltip id="tagihan" type="dark">
                                <span>Struk Tagihan</span>
                              </ReactTooltip>
                              <div 
                                data-tip data-for="rujukan"
                                className={el.resumeMedis && el.resumeMedis.referral && el.resumeMedis.referral.fileUrl ? "pointer" : ""} 
                                style={{
                                  marginRight: "10px",
                                  display: el.resumeMedis && el.resumeMedis.referral && el.resumeMedis.referral.fileUrl ? "" : "none"
                                }}
                                onClick={() => {
                                  setUrlPdf(el.resumeMedis.referral.fileUrl)
                                  setDocType("Surat Rujukan")
                                  setModalPdf(true)
                              }}><GrDocumentCloud /></div>
                              <ReactTooltip id="rujukan" type="info">
                                <span>Surat Rujukan</span>
                              </ReactTooltip>
                              <div 
                                data-tip data-for="sakit"
                                className={el.resumeMedis && el.resumeMedis.medicalCertificate && el.resumeMedis.medicalCertificate.fileUrl ? "pointer" : ""} 
                                style={{
                                  marginRight: "10px",
                                  display: el.resumeMedis && el.resumeMedis.medicalCertificate && el.resumeMedis.medicalCertificate.fileUrl ? "" : "none"
                                }}
                                onClick={() => {
                                  setUrlPdf(el.resumeMedis.medicalCertificate.fileUrl)
                                  setDocType("Surat Sakit")
                                  setModalPdf(true)
                              }}><GrDocumentTest /></div>
                              <ReactTooltip id="sakit" type="error">
                                <span>Surat Sakit</span>
                              </ReactTooltip>
                            </div>
                            /* <div className="hoverstyle">Struk Tagihan</div> */
                            // <div style={{ cursor: "pointer" }} onClick={() => navigate(`/result/${el.employee.employeeIdMobile}`)}>Lihat Hasil</div>
                          ) : (
                            <div style={{ color: "green" }}>{el.status}</div>
                          )}
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot className="tablefooter">
                <tr className="row">
                  <td>
                    <button
                      className="pagination"
                      onClick={() => setActivePage(activePage - 1)}
                      disabled={activePage === 1}
                    >
                      {"<"}
                    </button>
                  </td>
                  {totalPage > 0 &&
                    arrayPage.map((el, idx) => {
                      return (
                        <td key={idx}>
                          <div
                            className="pagination"
                            style={{
                              backgroundColor:
                                activePage === el ? "#558EFF" : "transparent",
                              color: activePage === el ? "#FFFFFF" : "#25282B",
                            }}
                            onClick={() => setActivePage(el)}
                          >
                            {el}
                          </div>
                        </td>
                      );
                    })}
                  <td>
                    <button
                      className="pagination"
                      onClick={() => setActivePage(activePage + 1)}
                      disabled={activePage >= totalPage}
                    >
                      {">"}
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
      </div>
      {modalPdf && (
        <ModalPDFViewer title={docType} modal={setModalPdf} url={urlPdf} />
      )}
    </div>
  );
}

export default ListKlinik;
