import {
  setIsLogin,
  setCloseAllModal,
  setModalClicked,
  setLoading,
  setErrorMessage,
  login,
  register,
  createClinic,
  getAllClinic,
  getClinicById,
  getClinicByName,
  getServices,
  getAllClinicWeb,
  updateClinicService,
  deleteClinic,
  uploadDocument,
  deleteDocument,
  uploadBuktiTransfer,
  getReservasiData,
  getDataDashboard,
  setRangeDashboardData,
  getUserData,
  editUserData,
  changePassword,
  getResetPassword,
  verifyToken,
  createPassword,
  getDetailEvents,
  setDataDetailEvents
} from "./corpAction";
import {
  getEmployees,
  createEmployee,
  createBulkEmployee,
  setSuccessMessage,
  createEvent,
  updateEmployee,
  getEmployeesVerified
} from "./employeeAction";
import {
  setResumeMedisPatient,
  getResumeMedisByDate,
  getResumeMedisPatient,
  getTodayActivity
} from "./resumeMedisAction"


export {
  setIsLogin,
  setCloseAllModal,
  setModalClicked,
  setLoading,
  setErrorMessage,
  setSuccessMessage,
  getDataDashboard,
  setRangeDashboardData,
  getUserData,
  editUserData,
  changePassword,
  getResetPassword,
  verifyToken,
  createPassword,
  getDetailEvents,
  setDataDetailEvents,

  login,
  register,

  createClinic,
  getAllClinic,
  getClinicById,
  getClinicByName,
  getAllClinicWeb,
  getServices,
  deleteClinic,
  uploadDocument,
  deleteDocument,
  updateClinicService,
  uploadBuktiTransfer,

  getReservasiData,

  getEmployees,
  getEmployeesVerified,
  createEmployee,
  updateEmployee,
  createBulkEmployee,
  createEvent,

  setResumeMedisPatient,
  getResumeMedisByDate,
  getResumeMedisPatient,
  getTodayActivity
};
